<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{titulo}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">  
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                    <v-text-field
                        label="Período Inicial"
                        v-model="filtro.mesInicial"
                        :rules="regra.mesInicial"
                        required
                        outlined
                        dense 
                        type="month"
                        :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                    <v-text-field
                        label="Período Final"
                        v-model="filtro.mesFinal"
                        :rules="regra.mesFinal"
                        required
                        outlined
                        dense 
                        type="month"
                        :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                    ></v-text-field>
                </v-col> 
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="6" md="5" lg="3" xl="3" class="px-2 ml-auto" align="end">
                    <v-btn
                        class="ml-2 mb-1"
                        color="primary"
                        @click="limpar()"
                    >
                        <v-icon left>mdi-filter-off</v-icon> Limpar
                    </v-btn> 
                    <v-btn
                        class="ml-2 mb-1"
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.data_criacao`]="{ item }"> 
                    {{item.data_nome}}
                </template>
            </v-data-table>
        </v-card>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            titulo: 'Acessos',
            headers: [
                { text: 'ID Login', value: 'id_login', align: 'center'},
                { text: 'IP', value: 'ip', align: 'center'},
                { text: 'Sistema', value: 'sistema', align: 'center'},
                { text: 'ID Usuário', value: 'id_usuario', align: 'center'},
                { text: 'Usuário', value: 'usuario', align: 'center'},
                { text: 'Data', value: 'data_criacao', align: 'center'},
            ],
            filtro: {},
            dados: [],
            pagination: {},
            paginationLimpa: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 5,
                multiSort: true,
                mustSort: false,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
            total: null,
            carregando: false,
            busca: null,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                mesInicial: [
                    v => !!v || 'Campo obrigatório.',
                ],
                mesFinal: [
                    v => !!v || 'Campo obrigatório.',
                    v => (!!v && v >= this.filtro.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
                ],
            },
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){
                    
                    this.filtrar()
                }
            }, 1000)
        },
    },
    async created() {

        this.filtro.mesInicial = this.mesAtual
        this.filtro.mesFinal = this.mesAtual
    },
    computed: { 
        mesAtual() {
            
            return this.$moment().format('YYYY-MM')
        },
       diaAtual() {
            
            return this.$moment().format('YYYY-MM-DD')
        },
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination
            data.busca = this.busca
            data.filtro = this.filtro

            var response = await this.$http().post(`/gerencial/login`, {data: data})
            
            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.carregando = false
        },
        limpar() {

            this.pagination = {...this.paginationLimpa}

            this.filtro = {
                mesInicial: this.mesAtual,
                mesFinal: this.mesAtual,
            }
        },
        async exportar() {
           
            this.carregando = true
            
            var data = {}

            data.filtro = this.filtro

            var response = await this.$http().post(`/gerencial/exportarLogin`, {data: data})
          
            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        },
    },
}
</script>