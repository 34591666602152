<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Acordos</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Ativos"
                        :items="[{text:'Sim', value: 1},{text:'Não', value: 0},{text:'Todos', value: null}]"
                        v-model="ativo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.desmembrar`]="{ item }">
                    {{ lista.desmembrar.find(e => e.value == item.desmembrar) ? lista.desmembrar.find(e => e.value == item.desmembrar).text : '' }}
                </template>
                <template v-slot:[`item.editar`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
            <!-- <v-alert
                class="mt-4"
                text
                type="info"
                border="left"
                style="font-size: 12px;"
            >
                <ul>
                    <li>
                        Observações
                    </li>
                </ul>
            </v-alert> -->
        </v-card>

        <!-- Modelo de Acordos -->
        <v-dialog
            v-model="dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Modelo de Acordos
                </v-card-title>
                <v-card-text class="mt-2"> 
                    <v-form ref="form"> 
                        <v-tabs v-model="tab" class="mb-4">
                            <v-tab>Dados do Modelo</v-tab>
                            <v-tab v-if="obj.id_modelo">Cadastro de Acordo</v-tab>
                            <v-tab v-if="obj.id_modelo">Seleção de Filiais</v-tab>
                            <v-tab v-if="obj.id_modelo">Desconto</v-tab>
                            <v-tab v-if="obj.id_modelo">Produtos</v-tab>
                            <v-tab v-if="obj.id_modelo">Planograma</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item key="Dados do Modelo">
                                 <v-row no-gutters class="mt-4">
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-text-field
                                            outlined
                                            dense 
                                            disabled
                                            label="ID Modelo"
                                            v-model="obj.id_modelo"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Modelo"
                                            v-model="obj.nome"
                                            :rules="regra.obrigatorio"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Modelo de Preço"
                                            :items="lista.preco"
                                            v-model="obj.id_modelo_preco"
                                            outlined
                                            dense 
                                            clearable
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Modelo de Inventário"
                                            :items="lista.inventario"
                                            v-model="obj.id_modelo_inventario"
                                            outlined
                                            dense 
                                            clearable
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Limite inicial do período"
                                            :items="lista.mes_limite"
                                            v-model="obj.mes_limite"
                                            :rules="regra.obrigatorio"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Ordem"
                                            v-model="obj.ordem"
                                            :rules="regra.obrigatorio"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Desmembrar acordo"
                                            :items="lista.desmembrar"
                                            v-model="obj.desmembrar"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Ativo"
                                            :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                            v-model="obj.ativo"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="% de Execução para Auditoria"
                                            v-model="obj.percentual_executado"
                                            :rules="regra.percentual_executado"
                                            type="number"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-textarea
                                            label="Dados Bancários"
                                            v-model="obj.dados_bancarios"
                                            rows="2"
                                            outlined
                                            dense 
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item v-for="(passo, index) in passos" :key="passo">
                                <v-row no-gutters class="mt-4">
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2">
                                        <v-select
                                            label="Ativo"
                                            :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                            v-model="obj.passo[index].ativo"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2">
                                        <v-switch
                                            label="Habilitar Observação"
                                            v-model="obj.passo[index].obs"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Observação Nome"
                                            v-model="obj.passo[index].obs_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Habilitar Acordo Cliente 1"
                                            v-model="obj.passo[index].acordo_cliente_1"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Acordo Cliente 1 Obrigatório"
                                            v-model="obj.passo[index].acordo_cliente_1_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Acordo Cliente 1 Por Parcela"
                                            v-model="obj.passo[index].acordo_cliente_1_periodo"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Acordo Cliente 1 Nome"
                                            v-model="obj.passo[index].acordo_cliente_1_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Habilitar Acordo Cliente 2"
                                            v-model="obj.passo[index].acordo_cliente_2"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Acordo Cliente 2 Obrigatório"
                                            v-model="obj.passo[index].acordo_cliente_2_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Acordo Cliente 2 Por Parcela"
                                            v-model="obj.passo[index].acordo_cliente_2_periodo"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Acordo Cliente 2 Nome"
                                            v-model="obj.passo[index].acordo_cliente_2_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Habilitar Acordo Cliente 3"
                                            v-model="obj.passo[index].acordo_cliente_3"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Acordo Cliente 3 Obrigatório"
                                            v-model="obj.passo[index].acordo_cliente_3_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Acordo Cliente 3 Por Parcela"
                                            v-model="obj.passo[index].acordo_cliente_3_periodo"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Acordo Cliente 3 Nome"
                                            v-model="obj.passo[index].acordo_cliente_3_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Data Inicial do Contrato"
                                            v-model="obj.passo[index].contrato_data_inicial"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Data Inicial do Contrato Obrigatório"
                                            v-model="obj.passo[index].contrato_data_inicial_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Data Final do Contrato"
                                            v-model="obj.passo[index].contrato_data_final"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Data Final do Contrato Obrigatório"
                                            v-model="obj.passo[index].contrato_data_final_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="[1,2,3,4].includes(index)">
                                        <v-switch
                                            label="Obrigatório"
                                            v-model="obj.passo[index].obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row 
                                    v-if="[0,1,3].includes(index)"
                                    no-gutters 
                                    class="mb-2"
                                >
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="end">
                                        <v-btn
                                            color="primary"
                                            class="pr-4"
                                            @click="novoAtributo(index)"
                                        >
                                            <v-icon left>mdi-plus</v-icon> Adicionar Atributo
                                        </v-btn> 
                                    </v-col>
                                </v-row>
                                <v-data-table
                                    v-if="[0,1,3].includes(index)"
                                    :headers="atributo.headers[index]"
                                    :items="obj.passo[index].passo_tabela"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="atributo.pagination"
                                    class="elevation-0"
                                >
                                    <template v-slot:[`item.editar`]="{ item }"> 
                                        <v-icon
                                            small
                                            class="mr-2"
                                            color="primary"
                                            @click="editarAtributo(item, index)"
                                            title="Editar Atributo"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <template v-slot:[`item.remover`]="{ item }">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            color="primary"
                                            @click="removerAtributoConfirm(item)"
                                            title="Remover Atributo"
                                        >
                                            mdi-trash-can
                                        </v-icon>
                                    </template>
                                </v-data-table>
                                <!-- <v-alert
                                    v-if="index == 1"
                                    class="mt-4"
                                    text
                                    type="info"
                                    border="left"
                                    style="font-size: 12px;"
                                >
                                    <ul>
                                        <li>
                                            observações
                                        </li>
                                    </ul>
                                </v-alert> -->
                            </v-tab-item>
                        </v-tabs-items>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Adicionar Atributo -->
        <v-dialog
            v-model="atributo.dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Atributo
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-form ref="formAtributo">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="ID Passo"
                                    v-model="atributo.obj.id_passo"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                <v-select
                                    label="Atributo"
                                    :items="atributo.lista.tabela.filter(e => !obj.passo[atributo.passo].passo_tabela.map(a => a.tabela).includes(e.value) || atributo.obj.editar)"
                                    v-model="atributo.obj.tabela"
                                    :rules="regra.obrigatorio"
                                    :disabled="atributo.obj.editar"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="atributo.headers[atributo.passo].map(e => e.value).includes('tipo')"
                            >
                                <v-select
                                    label="Tipo"
                                    :items="!lista.assinatura.map(e => e.id_modelo.toString()).includes(atributo.obj.tabela) ? atributo.lista.tipo[atributo.passo] : ['assinatura_setor']"
                                    v-model="atributo.obj.tipo"
                                    :rules="regra.obrigatorio"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="atributo.headers[atributo.passo].map(e => e.value).includes('valor') && atributo.obj.tipo == 'constante'"
                            >
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Valor"
                                    v-model="atributo.obj.valor"
                                    :rules="regra.valor"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense
                                    label="Ordem"
                                    v-model="atributo.obj.ordem"
                                    :rules="regra.obrigatorio"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="atributo.obj.tipo == 'campo'"
                            >
                                <v-switch
                                    label="Permitir Editar"
                                    v-model="atributo.obj.editar_valor"
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <!-- <v-alert
                            v-show="tab == 2"
                            class="mt-4"
                            text
                            type="info"
                            border="left"
                            style="font-size: 12px;"
                        >
                            <ul>
                                <li>
                                    obs
                                </li>
                            </ul>
                        </v-alert> -->
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="atributo.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvarAtributo()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Remover Atributo -->
        <v-dialog
            v-model="remover.dialogo"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{remover.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    {{remover.texto}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="remover.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="error"
                        class="pr-4"
                        @click="removerAtributo()"
                    >
                        <v-icon left>mdi-close</v-icon> Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tab: null,
            headers: [
                { text: 'ID Modelo', value: 'id_modelo', align: 'center'},
                { text: 'Modelo', value: 'nome', align: 'center'},
                { text: 'Limite inicial do período', value: 'mes_limite_nome', align: 'center'},
                { text: 'ID Preço', value: 'id_modelo_preco', align: 'center'},
                { text: 'Preço', value: 'modelo_preco', align: 'center'},
                { text: 'ID Inventário', value: 'id_modelo_inventario', align: 'center'},
                { text: 'Inventário', value: 'modelo_inventario', align: 'center'},
                { text: 'Ordem', value: 'ordem', align: 'center'},
                { text: '% de Execução', value: 'percentual_executado', align: 'center'},
                { text: 'Desmembrar', value: 'desmembrar', align: 'center'},
                { text: 'Ativo', value: 'ativo_nome', align: 'center'},
                { text: '', value: 'editar', align: 'center', sortable: false },
            ],
            dados: [],
            lista: {
                mes_limite: [],
                preco: [],
                inventario: [],
                assinatura: [],
                desmembrar: [],
            },
            passos: [
                'Cadastro de Acordo',
                'Seleção de Filiais',
                'Desconto',
                'Produtos',
                'Planograma',
            ],
            objNovo: {
                id_modelo: null,
                nome: null,
                id_modelo_preco: null,
                id_modelo_inventario: null,
                mes_limite: null,
                desmembrar: null,
                ativo: 1,
                percentual_executado: 100,
                passo: [
                    {obs: false, obs_nome: 'Observação', ativo: 1, passo_tabela: [], obrigatorio: true},
                    {obs: false, obs_nome: 'Observação', ativo: 1, passo_tabela: [], obrigatorio: true},
                    {obs: false, obs_nome: 'Observação', ativo: 1, passo_tabela: [], obrigatorio: true},
                    {obs: false, obs_nome: 'Observação', ativo: 1, passo_tabela: [], obrigatorio: true},
                    {obs: false, obs_nome: 'Observação', ativo: 1, passo_tabela: [], obrigatorio: true},
                ]
            },
            obj: {},
            pagination: {},
            total: null,
            carregando: false, 
            ativo: 1,
            dialogo: false,
            atributo: {
                headers: [
                    [
                        { text: 'ID Passo', value: 'id_passo', align: 'center'},
                        { text: 'Atributo', value: 'nome', align: 'center'},
                        { text: 'Tipo', value: 'tipo', align: 'center'},
                        { text: 'Valor', value: 'valor', align: 'center'},
                        { text: 'Ordem', value: 'ordem', align: 'center'},
                        { text: '', value: 'editar', align: 'center', sortable: false },
                        { text: '', value: 'remover', align: 'center', sortable: false },
                    ],
                    [
                        { text: 'ID Passo', value: 'id_passo', align: 'center'},
                        { text: 'Atributo', value: 'nome', align: 'center'},
                        { text: 'Ordem', value: 'ordem', align: 'center'},
                        { text: '', value: 'editar', align: 'center', sortable: false },
                        { text: '', value: 'remover', align: 'center', sortable: false },
                    ],
                    [],
                    [
                        { text: 'ID Passo', value: 'id_passo', align: 'center'},
                        { text: 'Atributo', value: 'nome', align: 'center'},
                        { text: 'Ordem', value: 'ordem', align: 'center'},
                        { text: '', value: 'editar', align: 'center', sortable: false },
                        { text: '', value: 'remover', align: 'center', sortable: false },
                    ],
                    [],
                ],
                pagination: {},
                dialogo: false,
                objNovo: {
                    id_passo: null,
                    tabela: null,
                    nome: null,
                    tipo: null,
                    valor: null,
                    ordem: null,
                    editar_valor: null,
                    editar: false,
                },
                obj: {},
                passo: 0,
                lista: {
                    tabela: [],
                    tipo: [
                        ['campo', 'constante'],
                        ['filial'],
                        [],
                        ['produto'],
                        [],
                    ],
                },
            },
            remover: {
                dialogo: false,
                titulo: null,
                texto: null
            },
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                obrigatorio: [v => (!!v || v == 0) || 'Campo obrigatório.'],
                percentual_executado: [
                    v => !!v || 'Campo obrigatório.',
                    v => !v || v >= 0 && v <= 100 || 'O percentual deve ser um valor entre 0 e 100.',
                ],
                nome: [
                    v => !!v || 'Campo obrigatório.',
                    v => !v || /^[a-z0-9]+$/.test(v) || 'O campo cliente so pode ter letras minusculas e números.',
                    v => !v || /^[a-z]/.test(v) || 'O campo cliente deve começar com uma letras minuscula.',
                ],
                valor: [
                    v => this.atributo.obj.tipo != 'constante' || !!v || 'O valor é obrigatório para atributos constantes.',
                ],
                origem: [
                    v => ['pagamento', 'tabela_1', 'tabela_2', 'tabela_3', 'tabela_4'].includes(this.atributo.obj.tabela) || this.atributo.obj.tipo != 'campo' || this.lista.tabelasDesdobramentoLoja.includes(this.atributo.obj.tabela) || !!v || 'A origem é obrigatória para atributos fora do desdobramento e diferentes de loja ou pagamento.',
                ],
            },
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        ativo() {

            this.filtrar()
        },
    },
    async created() {

        this.obj = {...this.objNovo}

        this.lista.preco = ( await this.$http().post(`/precoModelo/filtro`, {data: {}}) ).data

        this.lista.inventario = ( await this.$http().post(`/inventarioModelo/filtro`, {data: {}}) ).data

        for (let m = -12; m <= 12; m++) {

            let nome = ''

            if(m > 1) nome = m + ' meses'
            if(m == 1) nome = '1 mês'
            if(m == 0) nome = 'Mês atual'
            if(m == -1) nome = '1 mês retroativo'
            if(m < -1) nome = Math.abs(m) + ' meses retroativos'

            this.lista.mes_limite.push({value: m, text: nome})
        }

        this.lista.tabela = ( await this.$http().post('/configuracao/tabelas', {data: {}}) ).data

        this.lista.loja = ( await this.$http().post('/configuracao/lista', {data: {tabela: 'loja'}}) ).data.lista

        this.lista.desmembrar = this.lista.loja.filter(e => e.ad.split('id_')[1] && e.obrigatorio).map(e => ({value: e.ad, text: 'Desmembar por '+e.nome}))

        this.lista.desmembrar.unshift({value: 'nao_desmembrar', text: 'Não desmembar'})

        this.lista.produto = ( await this.$http().post('/configuracao/lista', {data: {tabela: 'produto'}}) ).data.lista

        this.lista.assinatura = ( await this.$http().post('/assinaturaModelo/filtro', {data: {}}) ).data
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/modelo/lista`, {data: data})
            
            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.carregando = false
        },
        novo() {
           
            this.obj = {...this.objNovo}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        },
        editar(item) {
           
            this.obj = {...item}

            console.log(this.obj)

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }
            
            this.dialogo = true
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }
            
            if(!this.$refs.form.validate()) return
            
            this.carregando = true
            
            var data = this.obj

            var response = await this.$http().post(`/modelo/salvar`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        passoAtributos(passo) {

            this.atributo.passo = passo

            this.atributo.lista.tabela = []

            if (passo == 0) {

                this.atributo.lista.tabela = this.lista.tabela.filter(e => ['acao', 'fornecedor', 'pagamento', 'tabela_1', 'tabela_2', 'tabela_3', 'tabela_4', 'tabela_5', 'tabela_6', 'tabela_7', 'tabela_8'].includes(e.tabela)).map(e => ({value: e.tabela, text: e.nome}))

                for (const a of this.lista.assinatura.filter(e => e.tipo == 'usuario_fornecedor')) {

                    this.atributo.lista.tabela.push({value: a.id_modelo.toString(), text: 'Usuário assinatura - ' + a.nome})
                }
            }

            if (passo == 1) {

                this.atributo.lista.tabela = this.lista.loja.filter(e => e.ativo && !['nome', 'cnpj_matriz', 'matriz', 'filial'].includes(e.ad)).map(e => ({value: e.ad, text: e.nome}))
            }

            if (passo == 3) {

                this.atributo.lista.tabela = this.lista.produto.filter(e => e.ativo && !['codigo'].includes(e.ad)).map(e => ({value: e.ad, text: e.nome}))
            }
        },
        novoAtributo(passo) {

            this.atributo.obj = {...this.atributo.objNovo}

            this.passoAtributos(passo)

            this.atributo.obj.id_passo = this.obj.passo[passo].id_passo

            if (passo == 1) {

                this.atributo.obj.tipo = 'filial'
            }

            if (passo == 3) {

                this.atributo.obj.tipo = 'produto'
            }

            try {
                this.$refs.formAtributo.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.atributo.dialogo = true
        },
        editarAtributo(atributo, passo) {

            this.atributo.obj = {...atributo}

            this.atributo.obj.editar = true

            this.passoAtributos(passo)

            try {
                this.$refs.formAtributo.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.atributo.dialogo = true
        },
        async salvarAtributo() {

            if(!this.$refs.formAtributo.validate()) return

            this.carregando = true
            
            var data = this.atributo.obj

            var response = await this.$http().post(`/modelo/salvarAtributo`, {data: data})

            this.filtrar()

            setInterval(() => {
                
                this.carregando = true

                this.obj = {...this.dados.find(e => e.id_modelo == this.obj.id_modelo)}

                this.carregando = false
            }, 100)

            if(response.data.sucesso){

                this.atributo.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        removerAtributoConfirm(atributo) {

            this.atributo.obj = {...atributo}

            this.remover.titulo = 'Remover Atributo'
            this.remover.texto = `Deseja realmente remover o atributo '${atributo.nome}'?`
            
            this.remover.dialogo = true
        }, 
        async removerAtributo() {

            this.carregando = true

            var data = this.atributo.obj

            var response = await this.$http().post(`/modelo/removerAtributo`, {data: data})
            
            this.filtrar()

            setInterval(() => {
                
                this.carregando = true

                this.obj = {...this.dados.find(e => e.id_modelo == this.obj.id_modelo)}

                this.carregando = false
            }, 100)
            
            this.carregando = false

            if(response.data.sucesso){

                this.remover.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        }, 
    },
}
</script>