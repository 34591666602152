import Vue from 'vue'
import VueRouter from 'vue-router'

import Clientes from '../views/Clientes.vue'
import Login from '../views/Login.vue'
import NovaSenha from '../views/NovaSenha.vue'
import Main from '../views/Main.vue'
import Home from '../views/Home.vue'
import Perfil from '../views/Perfil.vue'
import PrimeiroAcesso from '../views/PrimeiroAcesso.vue'
import Sso from '../views/Sso.vue'

import cargo from '../views/cadastro/cargo/Lista.vue'
import setor from '../views/cadastro/setor/Lista.vue'
import hierarquia from '../views/cadastro/hierarquia/Lista.vue'
import usuario from '../views/cadastro/usuario/Lista.vue'
import regiao from '../views/cadastro/regiao/Lista.vue'
import cluster from '../views/cadastro/cluster/Lista.vue'
import bandeira from '../views/cadastro/bandeira/Lista.vue'
import canal from '../views/cadastro/canal/Lista.vue'
import loja from '../views/cadastro/loja/Lista.vue'
import marca from '../views/cadastro/marca/Lista.vue'
import produto from '../views/cadastro/produto/Lista.vue'
import acao from '../views/cadastro/acao/Lista.vue'
import pagamento from '../views/cadastro/pagamento/Lista.vue'
import tabela_1 from '../views/cadastro/tabela_1/Lista.vue'
import tabela_2 from '../views/cadastro/tabela_2/Lista.vue'
import tabela_3 from '../views/cadastro/tabela_3/Lista.vue'
import tabela_4 from '../views/cadastro/tabela_4/Lista.vue'
import tabela_5 from '../views/cadastro/tabela_5/Lista.vue'
import tabela_6 from '../views/cadastro/tabela_6/Lista.vue'
import tabela_7 from '../views/cadastro/tabela_7/Lista.vue'
import tabela_8 from '../views/cadastro/tabela_8/Lista.vue'
import macro_fornecedor from '../views/cadastro/macro_fornecedor/Lista.vue'
import fornecedor from '../views/cadastro/fornecedor/Lista.vue'
import espaco_macro from '../views/cadastro/espaco_macro/Lista.vue'
import espaco from '../views/cadastro/espaco/Lista.vue'

import permissao from '../views/cadastro/permissao/Lista.vue'
import configuracao from '../views/cadastro/configuracao/Lista.vue'
import precoModelo from '../views/cadastro/precoModelo/Lista.vue'
import inventarioModelo from '../views/cadastro/inventarioModelo/Lista.vue'
import desdobramento from '../views/cadastro/desdobramento/Lista.vue'
import modelo from '../views/cadastro/modelo/Lista.vue'
import assinaturaModelo from '../views/cadastro/assinaturaModelo/Lista.vue'
import status from '../views/cadastro/status/Lista.vue'
import processo from '../views/cadastro/processo/Lista.vue'
import filtro from '../views/cadastro/filtro/Lista.vue'
import justificativa from '../views/cadastro/justificativa/Lista.vue'
import recibo from '../views/cadastro/recibo/Lista.vue'
import lista from '../views/cadastro/lista/Lista.vue'

import acordo from '../views/acordo/listagem/Lista.vue'
import auditar from '../views/acordo/auditoria/Lista.vue'
import validar from '../views/acordo/validar/Lista.vue'

import preco from '../views/preco/Lista.vue'
import inventario from '../views/inventario/Lista.vue'

import auditoria from '../views/gerencial/auditoria/Lista.vue'
import oportunidade from '../views/gerencial/oportunidade/Lista.vue'
import investimento from '../views/gerencial/investimento/Lista.vue'
import acessos from '../views/gerencial/acessos/Lista.vue'

//adm
import cliente from '../views/cadastro/cliente/Lista.vue'
import servidor from '../views/cadastro/servidor/Lista.vue'
import atualizacao from '../views/cadastro/atualizacao/Lista.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'clientes', component: Clientes},
  {path: '/novaSenha', name: 'novaSenha', component: NovaSenha},  
  {path: '/primeiroAcesso', name: 'primeiroAcesso', component: PrimeiroAcesso},
  {path: '/sso', name: 'sso', component: Sso},
  {path: '/main', name: 'main', component: Main, children: [
      //layout
      {path: '/home', name: 'home', component: Home},
      {path: '/perfil', name: 'perfil', component: Perfil},
      //administrativo
      {path: '/cargo', name: 'cargo', component: cargo},
      {path: '/setor', name: 'setor', component: setor},
      {path: '/hierarquia/:tipo', name: 'hierarquia', component: hierarquia},
      {path: '/usuario', name: 'usuario', component: usuario},
      {path: '/regiao', name: 'regiao', component: regiao},
      {path: '/cluster', name: 'cluster', component: cluster},
      {path: '/bandeira', name: 'bandeira', component: bandeira},
      {path: '/canal', name: 'canal', component: canal},
      {path: '/loja', name: 'loja', component: loja},
      {path: '/marca', name: 'marca', component: marca},
      {path: '/produto', name: 'produto', component: produto},
      {path: '/acao', name: 'acao', component: acao},
      {path: '/pagamento', name: 'pagamento', component: pagamento},
      {path: '/tabela_1', name: 'tabela_1', component: tabela_1},
      {path: '/tabela_2', name: 'tabela_2', component: tabela_2},
      {path: '/tabela_3', name: 'tabela_3', component: tabela_3},
      {path: '/tabela_4', name: 'tabela_4', component: tabela_4},
      {path: '/tabela_5', name: 'tabela_5', component: tabela_5},
      {path: '/tabela_6', name: 'tabela_6', component: tabela_6},
      {path: '/tabela_7', name: 'tabela_7', component: tabela_7},
      {path: '/tabela_8', name: 'tabela_8', component: tabela_8},
      {path: '/macro_fornecedor', name: 'macro_fornecedor', component: macro_fornecedor},
      {path: '/fornecedor', name: 'fornecedor', component: fornecedor},
      {path: '/espaco_macro', name: 'espaco_macro', component: espaco_macro},
      {path: '/espaco', name: 'espaco', component: espaco},
      //configurações
      {path: '/permissao', name: 'permissao', component: permissao},
      {path: '/configuracao', name: 'configuracao', component: configuracao},
      {path: '/desdobramento', name: 'desdobramento', component: desdobramento},
      {path: '/precoModelo', name: 'precoModelo', component: precoModelo},
      {path: '/inventarioModelo', name: 'inventarioModelo', component: inventarioModelo},
      {path: '/modelo', name: 'modelo', component: modelo},
      {path: '/assinaturaModelo', name: 'assinaturaModelo', component: assinaturaModelo},
      {path: '/status', name: 'status', component: status},
      {path: '/processo', name: 'processo', component: processo},
      {path: '/filtro', name: 'filtro', component: filtro},
      {path: '/justificativa', name: 'justificativa', component: justificativa},
      {path: '/recibo', name: 'recibo', component: recibo},
      {path: '/lista', name: 'lista', component: lista},
      //acordo
      {path: '/acordo', name: 'acordo', component: acordo},
      {path: '/auditar', name: 'auditar', component: auditar},
      {path: '/validar', name: 'validar', component: validar},
      //preco / inventario
      {path: '/preco/:id', name: 'preco', component: preco},
      {path: '/inventario/:id', name: 'inventario', component: inventario},
      //gerenciais
      {path: '/auditoria', name: 'auditoria', component: auditoria},
      {path: '/oportunidade', name: 'oportunidade', component: oportunidade},
      {path: '/investimento', name: 'investimento', component: investimento},
      {path: '/acessos', name: 'acessos', component: acessos},
      //adm
      {path: '/cliente', name: 'cliente', component: cliente},
      {path: '/servidor', name: 'servidor', component: servidor},
      {path: '/atualizacao', name: 'atualizacao', component: atualizacao},
    ]
  }, 
  {path: '/:cliente', name: 'login', component: Login},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
