<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{titulo}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>    
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="abrirImportar()"
                >
                    <v-icon left>mdi-upload</v-icon> Importar
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary" 
                    @click="exportarHistorico({})"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar Histórico
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Ativos"
                        :items="[{text:'Sim', value: 1},{text:'Não', value: 0},{text:'Todos', value: null}]"
                        v-model="ativo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
                dense
                fixed-header
                :height="320"
                :items-per-page="10"
            >
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
                <template v-slot:[`item.historico`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="buscaHistorico(item)"
                        title="Histórico"
                    >
                        mdi-file-document-multiple
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{tituloDialogo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="form"> 
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    :label="'ID '+tituloDialogo"
                                    v-model="obj['id_'+tabela]"
                                ></v-text-field> 
                            </v-col>
                            <v-col v-for="(item, index) in ads" :key="index" cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-autocomplete v-if="lista.campos.includes(item.ad.substr(3))"
                                    :label="item.nome"
                                    :items="lista[item.ad.substr(3)]"
                                    v-model="obj[item.ad]"
                                    clearable
                                    outlined
                                    :rules="regra[item.ad]"
                                    dense 
                                    :multiple="!!item.ad.split('id_setor_')[1]"
                                >
                                </v-autocomplete>
                                 <v-select v-else-if="['matriz', 'filial'].includes(item.ad)"
                                    :label="item.nome"
                                    :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                    v-model="obj[item.ad]"
                                    outlined
                                    dense 
                                >
                                </v-select>
                                <v-text-field v-else-if="['cnpj','cnpj_matriz'].includes(item.ad)"
                                    outlined
                                    dense 
                                    :label="item.nome"
                                    v-model="obj[item.ad]"
                                    :rules="regra[item.ad]"
                                    v-mask="'##.###.###/####-##'"
                                ></v-text-field>
                                <v-text-field v-else
                                    outlined
                                    dense 
                                    :label="item.nome"
                                    v-model="obj[item.ad]"
                                    :rules="regra[item.ad]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Ativo"
                                    :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                    v-model="obj.ativo"
                                    outlined
                                    dense 
                                >
                                </v-select> 
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoImportar"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Importar CSV
                </v-card-title>
                <v-card-text>
                    <v-form ref="formImport">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-select
                                    label="Tipo de Importação"
                                    :items="[{value: 'nome', text: 'Por '+nomeCampo}, {value: 'id', text: 'Por ID '+tituloDialogo}]"
                                    v-model="importacaoTipo"
                                    outlined
                                    dense 
                                    hide-details
                                >
                                </v-select> 
                            </v-col>
                            <v-col cols="6">
                            </v-col>
                            <v-col cols="6">
                                <v-checkbox
                                    v-model="cadastrar"
                                    :label="`Cadastrar/Ativar objetos não encontrados.`"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="6" class="mt-2" v-show="cadastrar && this.ads.find(e => e.ad == 'id_setor')"> 
                                <v-select
                                    label="Cargo"
                                    :items="lista.cargo"
                                    v-model="id_cargo"
                                    :rules="regra.id_cargo"
                                    outlined
                                    dense 
                                >
                                </v-select> 
                            </v-col>
                        </v-row> 
                        <v-alert
                            text
                            type="info"
                            border="left"
                            style="font-size: 12px;"
                        >
                            <ul>
                                <li>
                                    Os nomes das colunas devem ser <b>idênticos</b> aos da base exportada.
                                </li>
                                <li>
                                    Coluna(s) obrigatória(s): <b>{{colunasObrigatorias}}</b>.
                                </li>
                                <li v-if="importacaoTipo == 'nome'">
                                    A coluna <b>{{nomeCampo}}</b> é a coluna <b>chave</b> para cadastros e atualizações. 
                                </li>
                                <li v-if="importacaoTipo == 'nome'">
                                    Linhas com <b>{{nomeCampo}}</b> não cadastrado serão cadastradas. 
                                </li>
                                <li v-if="importacaoTipo == 'nome'">
                                    Linhas com <b>{{nomeCampo}}</b> já cadastrado serão atualizadas. No caso de mais de uma linha com o mesmo <b>{{nomeCampo}}</b>, a última linha irá <b>sobrescrever</b> as anteriores.
                                </li>
                                <li v-if="importacaoTipo == 'id'">
                                    Linhas com <b>id_{{tabela}}</b> em branco serão <b>inseridas</b>. 
                                </li>
                                <li v-if="importacaoTipo == 'id'">
                                    Linhas com <b>id_{{tabela}}</b> com valor serão <b>atualizadas</b>. No caso de mais de uma linha com o mesmo <b>id_{{tabela}}</b>, a última linha irá <b>sobrescrever</b> as anteriores. 
                                </li>
                                <li>
                                    Na coluna <b>ativo</b> coloque <b>0</b> para <b>desativar</b> e qualquer valor <b>diferente de 0</b> para <b>ativar</b>.
                                </li>
                                <li v-if="ads.find(e => e.ad == 'matriz')">
                                    Na coluna <b>{{ads.find(e => e.ad == 'matriz').nome}}</b> coloque <b>0</b> para <b>NÃO</b> e qualquer valor <b>diferente de 0</b> para <b>SIM</b>.
                                </li>
                                <li v-if="ads.find(e => e.ad == 'filial')">
                                    Na coluna <b>{{ads.find(e => e.ad == 'filial').nome}}</b> coloque <b>0</b> para <b>NÃO</b> e qualquer valor <b>diferente de 0</b> para <b>SIM</b>.
                                </li>
                                <li v-if="ads.find(e => e.ad == 'cnpj')">
                                    Na coluna <b>{{ads.find(e => e.ad == 'cnpj').nome}}</b> o formato deve ser <b>NN.NNN.NNN/NNNN-NN</b>.
                                </li>
                                <li v-if="ads.find(e => e.ad == 'cnpj_matriz')">
                                    Na coluna <b>{{ads.find(e => e.ad == 'cnpj_matriz').nome}}</b> o formato deve ser <b>NN.NNN.NNN/NNNN-NN</b>.
                                </li>
                            </ul> 
                        </v-alert> 
                        <v-row no-gutters>
                            <v-col cols="8" class="px-2">
                                <v-file-input 
                                    label="Arquivo CSV" 
                                    outlined 
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-col> 
                            <v-col cols="4" class="px-2">
                                <v-btn
                                    color="primary"
                                    @click="importar()"
                                >
                                    <v-icon left>mdi-upload</v-icon> 
                                    Importar Arquivo
                                </v-btn>
                            </v-col> 
                        </v-row> 
                    </v-form>
                    <v-expand-transition>
                        <div v-show="erro">
                            <v-row no-gutters class="mb-0">  
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name= "erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left>mdi-microsoft-excel</v-icon>
                                            Exportar Logs
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row> 
                            <v-data-table
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros" 
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportar=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="historico.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Histórico
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters class="mt-2 mb-2">
                        <v-col cols="6">
                        </v-col>
                        <v-col cols="6" align="end">  
                            <v-btn
                                class="ml-2"
                                color="primary" 
                                @click="exportarHistorico({fnId: true})"  
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="historico.headers"
                        :items="historico.dados"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        class="elevation-0"
                    >
                    </v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="historico.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tabela: 'loja',
            titulo: 'Lojas',
            tituloDialogo: 'Loja',
            nomeCampo: null,
            headers: [
                { text: '', value: 'historico', align: 'center', sortable: false },
                { text: 'ID Loja', value: 'id_loja', align: 'center'},
                { text: 'Ativo', value: 'ativo_nome', align: 'center'},
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            ads: [],
            dados: [], 
            objNovo: {
                id_loja: null,
                nome: null,
                matriz: 0,
                filial: 0,
                ativo: 1,
                ad_1: null,
                ad_2: null,
                ad_3: null,
                ad_4: null,
                ad_5: null,
                ad_6: null,
                ad_7: null,
                ad_8: null,
                ad_9: null,
                ad_10: null,
            },
            obj: {},
            lista: {
                cargo: [],
                campos: [],
                loja: [],
                setor: [],
                regiao: [],
                cluster: [],
                bandeira: [],
                canal: [],
            },
            pagination: {}, 
            paginationErros: {}, 
            total: null,
            carregando: false, 
            ativo: 1,
            busca: null, 
            dialogo: false,
            dialogoImportar: false,
            colunasObrigatorias: '',
            importacaoTipo: 'nome',
            cadastrar: false,
            id_cargo: null,
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            arquivoCSV: null,
            regra: { 
                csv: [
                    v => !!v || 'Selecione um arquivo.',
                    v => (!!v && [
                        '.csv', 
                        '.CSV'
                    ].includes(v.name.substr(-4))) || 'O arquivo deve ser do tipo CSV.',
                ],
                id_cargo: [v => !this.ads.find(e => e.ad == 'id_setor') || !this.cadastrar || (this.cadastrar && !!v) || 'Selecione um cargo padrão para os setores.'],
            },  
            isCsv: false,
            headerErros: [
                { text: 'Descrição', value: 'msg', align: 'left'},
            ],
            erros: [],
            errosLabels: {msg: 'Descrição'}, 
            erro: false,
            historico: {
                headers: [],
                dados: [],
                dialogo: false,
                item: {}
            }
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){
                    
                    this.filtrar()
                }

            }, 1000)
        },
        ativo() {

            this.filtrar()
        },
    },
    async created() {
        
        var response = await this.$http().post(`/loja/filtros`, {}) 

        this.lista = response.data
        
        var data = {tabela: this.tabela}
        
        response = await this.$http().post(`/configuracao/tabela`, {data: data}) 

        for (const [i, e] of response.data.entries()) {

            if(i == 0) this.nomeCampo = e.nome

            this.ads.push(e)
            
            let ad = {
                text: e.nome, 
                value: e.ad.substr(0, 3) == 'id_' ? e.ad.substr(3) : ( ['matriz', 'filial'].includes(e.ad) ? `${e.ad}_nome` : e.ad ), 
                align: 'center'
            }
            
            this.headers.splice((this.headers.length-2), 0, ad)

            if ( ['cnpj', 'cnpj_matriz'].includes(e.ad) ) {

                this.regra[e.ad] = [
                    v => !e.obrigatorio || !!v && ( typeof v == 'string' ? !!v.trim() : true ) || 'Campo obrigatório.',
                    v => !e.obrigatorio || !v || /[0-9][0-9]\.[0-9][0-9][0-9]\.[0-9][0-9][0-9]\/[0-9][0-9][0-9][0-9]-[0-9][0-9]/.test(v) || 'O campo CNPJ deve esta no formato "NN.NNN.NNN/NNNN-NN".',
                ]
            }
            else {

                if (e.ad.split('id_setor_')[1]) {

                    this.regra[e.ad] = [v => !e.obrigatorio || !!v.length || 'Campo obrigatório.']
                }
                else {

                    this.regra[e.ad] = [v => !e.obrigatorio || !!v && ( typeof v == 'string' ? !!v.trim() : true ) || 'Campo obrigatório.']
                }
            }

            this.colunasObrigatorias += e.obrigatorio ? (this.colunasObrigatorias ? ', '+e.nome : e.nome) : ''
        }

        this.historico.headers = this.headers.map(e => ({...e}))
        this.historico.headers.shift()
        this.historico.headers.pop()
        this.historico.headers.unshift({ text: 'Data', value: 'data', align: 'center'})
        this.historico.headers.unshift({ text: 'Responsável', value: 'usuario_atualizacao', align: 'center'})
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination
            data.busca = this.busca

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/${this.tabela}/lista`, {data: data})

            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.carregando = false
        },
        async exportar() {

            this.carregando = true

            var data = {}

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/${this.tabela}/exportar`, {data: data})

            window.location = this.$http('baseURL')+response.data.url

            this.carregando = false
        }, 
        novo() {
           
            this.obj = {...this.objNovo}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        }, 
        editar(item) {

            this.obj = {...item}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }

            if(!this.$refs.form.validate()) return

            this.carregando = true

            var data = this.obj

            delete data.total

            var response = await this.$http().post(`/${this.tabela}/salvar`, {data: data})   

            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false  
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        }, 
        abrirImportar() {

            this.arquivoCSV = null
            this.erro = false
            this.cadastrar = false
            this.id_cargo = null

            try {
                this.$refs.formImport.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogoImportar = true 
        },
        async importar() {

            if(!this.$refs.formImport.validate()) return

            this.carregando = true

            var formData = new FormData()

            formData.append("files", this.arquivoCSV)
            formData.append("importacaoTipo", JSON.stringify(this.importacaoTipo))
            formData.append("cadastrar", JSON.stringify(this.cadastrar))
            formData.append("id_cargo", JSON.stringify(this.id_cargo))

            this.arquivoCSV = null

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            var response = await this.$http().post(`/${this.tabela}/importar`, formData)  

            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogoImportar = false
            }
            else{

                this.filtrar()
                this.erros = response.data.erros
                this.pagination.page = 1
                this.erro = true
            }
        },
        async buscaHistorico(item) {

            this.carregando = true

            this.historico.item = {...item}

            var data = {
                tabela: this.tabela,
                id: item['id_'+this.tabela],
            }

            var response = await this.$http().post(`/historico/lista`, {data: data})

            this.historico.dados = response.data

            this.carregando = false

            this.historico.dialogo = true
        },
        async exportarHistorico({fnId=false}) {

            this.carregando = true

            var data = {
                tabela: this.tabela,
                id: fnId ? this.historico.item['id_'+this.tabela] : false,
            }

            var response = await this.$http().post(`/historico/exportar`, {data: data})

            window.location = this.$http('baseURL')+response.data.url

            this.carregando = false
        },
    },
}
</script>